<template>
  <div class="config-tickets-dialog">
    <w-dialog
      ref="dialogRef"
      :title="agentTitle + '—已配置门票列表'"
      width="80%"
      btnWidth="140px"
      top="5vh"
      :hideFooter="true"
    >
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :filters="filters"
        :ispaging="true"
        :apiName="AgentApi.getConfigTickets"
        :extraParame="{ a_id: agentId }"
        :columns="tableColumns"
        @edit="openTicketsDialog"
        @statusChange="changeAgentStatus"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="openTicketsDialog"
            v-if="authData.indexOf('n_N8pJWs15zPm7byQMGHelusSbi08') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            添加门票</el-button
          >
        </template>
      </common-table>
      <TicketsDialog
        ref="ticketsDialogRef"
        @submit="submitTickets"
      ></TicketsDialog>
      <div class="tips">
        关闭门票展示状态，代理商将无法购买该优惠政策的门票。
      </div>
    </w-dialog>
  </div>
</template>

<script>
import { ref, nextTick, computed, watch } from "vue";
import { ElMessage } from "element-plus";
import { AgentApi, BasicApi } from "@/plugins/api.js";
import { useStore } from "vuex";
import TicketsDialog from "./TicketsDialog.vue";
export default {
  emits: ["reloadTable"],
  components: {
    TicketsDialog,
  },
  setup(props, { emit }) {
    const store = useStore();
    const menuTokens = computed(() => store.state.menuToken.menuTokens);
    const authData = ref([]);
    watch(
      () => menuTokens.value,
      (data) => {
        if (data.length) {
          authData.value = data;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const getProjectData = () => {
      BasicApi.projectSelect().then((res) => {
        if (res.Code === 200) {
          filters.value[0].options = res.Data ? res.Data : [];
        } else {
          let msg = res.Message ? res.Message : "获取项目数据失败！";
          ElMessage.error(msg);
        }
      });
    };
    const dialogRef = ref(null);
    const agentId = ref(null);
    const agentTitle = ref("");
    function openDialog(id, title) {
      agentTitle.value = title;
      agentId.value = id;
      getProjectData();
      dialogRef.value.show();
      nextTick(() => {
        tableRef.value.tableLoad();
      });
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    const filters = ref([
      {
        filterType: "select",
        name: "p_id",
        value: "",
        placeholder: "请选择项目",
        options: [],
        lab: "p_name",
        val: "p_id",
      },
    ]);
    /** 表格对象 */
    const tableRef = ref(null);

    /** 表格配置数据 */
    const tableColumns = ref([
      {
        prop: "auto_id",
        label: "序号",
        color: "--text-third-color",
        minWidth: 50,
      },
      {
        prop: "ticket_name",
        label: "门票名称",
      },

      {
        prop: "free_msg",
        label: "优惠",
        color: "--text-third-color",
        minWidth: 140,
      },
      {
        prop: "at_min_num",
        label: "起购数量",
      },
      {
        prop: "at_max_num",
        label: "限购数量",
      },
      {
        prop: "at_expire_day_mag",
        label: "使用期限",
        color: "--text-third-color",
        minWidth: 180,
      },
      {
        prop: "at_stime",
        label: "可购时间",
        color: "--text-third-color",
        prop2: "at_etime",
        type: "connect",
        connectText: "至",
        minWidth: 240,
      },
      {
        prop: "at_ctime",
        label: "添加时间",
        color: "--text-third-color",
        minWidth: 120,
      },
      {
        type: "switch",
        label: "展示状态",
        prop: "at_status",
        token: "n_MqrmARhWHgS3iYRHKqx7IZZ6AgN",
      },
      {
        type: "operation",
        prop: "",
        label: "操作",
        minWidth: 100,
        bottons: [
          {
            name: "编辑",
            action: "edit",
            HiddenKey: "at_status",
            showValue: [2],
            token: "n_7nt5XFDb3XunrbvnShqo5P87BeS",
          },
        ],
      },
    ]);

    const ticketsDialogRef = ref(null);
    const openTicketsDialog = (row) => {
      const rowData = row.at_id ? row : {};
      ticketsDialogRef.value.openDialog(rowData.at_id, agentTitle.value);
    };
    function submitTickets(data) {
      let url = "";
      if (data.at_id) {
        //编辑
        url = "editTicket";
      } else {
        //  新增
        url = "addTicket";
        data["at_aid"] = agentId.value;
      }
      AgentApi[url](data).then((res) => {
        if (res.Code === 200) {
          ElMessage.success("操作成功！");
          ticketsDialogRef.value.closeDialog();
          ticketsDialogRef.value.closeDialogLoading();
          tableRef.value.tableLoad();
          emit("reloadTable", true);
        } else {
          let msg = res.Message ? res.Message : "操作失败！";
          ticketsDialogRef.value.closeDialogLoading();
          ElMessage.error(msg);
        }
      });
    }
    function changeAgentStatus(row) {
      let data = {
        at_id: row.at_id,
        at_status: row.at_status == 1 ? 2 : 1,
      };
      AgentApi.configTicketsStatus(data).then((res) => {
        if (res.Code === 200) {
          ElMessage.success("开启成功！");
          tableRef.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : "开启失败！";
          ElMessage.error(msg);
        }
      });
    }

    return {
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      filters,
      AgentApi,
      authData,
      tableColumns,
      tableRef,
      ticketsDialogRef,
      openTicketsDialog,
      changeAgentStatus,
      agentId,
      submitTickets,
      agentTitle,
    };
  },
};
</script>

<style lang="scss">
.config-tickets-dialog {
  .tips {
    text-align: right;
    color: var(--text-gray-color);
  }
}
</style>