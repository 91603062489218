<template>
  <div class="agent-tickets-dialog">
    <w-dialog
      ref="dialogRef"
      :title="
        formData.at_id ? agentTitle + '—编辑门票' : agentTitle + '—新增门票'
      "
      width="50%"
      btnWidth="140px"
      top="20vh"
      :confirmText="formData.at_id ? '确认编辑' : '确认新增'"
      @wConfirm="handleSure"
    >
      <el-form
        class="add-form"
        ref="formRef"
        :model="formData"
        :rules="formRules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="项目" prop="at_pid">
              <el-select v-model="formData.at_pid" placeholder="请选择项目">
                <el-option
                  :label="item.p_name"
                  :value="item.p_id"
                  v-for="item in projectOptions"
                  :key="item.p_id"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="门票" prop="at_stid">
              <el-select v-model="formData.at_stid" placeholder="请选择门票">
                <el-option
                  :label="item.st_name"
                  :value="item.st_id"
                  v-for="item in ticketOptions"
                  :key="item.st_id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="优惠类型" prop="at_type">
              <el-select
                v-model="formData.at_type"
                placeholder="请选择优惠类型"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in typeOptions"
                  :key="item.id"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12" class="preferential-policy">
            <el-row :gutter="0">
              <el-col :span="15">
                <el-form-item label="优惠政策" prop="at_free_base_num">
                  <el-input
                    type="number"
                    v-model="formData.at_free_base_num"
                    placeholder=" "
                    clearable
                  >
                    <template #prepend>每买</template>
                    <template #append>张，送</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item prop="at_free_num" label=" " class="hide">
                  <el-input
                    type="number"
                    v-model="formData.at_free_num"
                    placeholder=" "
                    clearable
                    ><template #append>张</template></el-input
                  >
                </el-form-item></el-col
              >
            </el-row>
          </el-col> </el-row
        ><el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="起购数量" prop="at_min_num">
              <el-input
                type="number"
                v-model="formData.at_min_num"
                clearable
                placeholder="请输入起购数量"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="限购数量" prop="at_max_num">
              <el-input
                type="number"
                v-model="formData.at_max_num"
                clearable
                placeholder="请输入限购数量"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="门票购买后有效期" prop="at_expire_day">
              <el-input
                type="number"
                v-model="formData.at_expire_day"
                clearable
                placeholder="请输入门票购买后有效期"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="代理商可购买时间" prop="useDate">
              <el-date-picker
                v-model="formData.useDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>
// 
<script>
import { ref, reactive } from "vue";
import { ElMessage } from "element-plus";
import { BasicApi, AgentApi } from "@/plugins/api.js";
import dayjs from "dayjs";

export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const formData = ref({
      at_pid: "",
      at_stid: "",
      at_type: "",
      at_free_base_num: "",
      at_free_num: "",
      at_min_num: "",
      at_max_num: "",
      at_expire_day: "",
      useDate: "",
    });

    const dialogRef = ref(null);
    const formRules = reactive({
      at_pid: [
        {
          required: true,
          message: "请选择项目",
          trigger: "change",
        },
      ],
      at_stid: [
        {
          required: true,
          message: "请选择门票",
          trigger: "change",
        },
      ],
      at_type: [
        {
          required: true,
          message: "请输入优惠类型",
          trigger: "blur",
        },
      ],
      at_free_base_num: [
        {
          required: true,
          message: "请输入购买数量(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      at_free_num: [
        {
          required: true,
          message: "请输入赠送数量(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      at_min_num: [
        {
          required: true,
          message: "请输入起购数量(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      at_max_num: [
        {
          required: true,
          message: "请输入限购数量(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      at_expire_day: [
        {
          required: true,
          message: "请输入门票购买后有效期(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      useDate: [
        {
          required: true,
          message: "请选择代理商可购买时间",
          trigger: "change",
        },
      ],
    });

    const projectOptions = ref([]);
    const getProjectData = () => {
      BasicApi.projectSelect().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            projectOptions.value = res.Data;
          }
        } else {
          let msg = res.Message ? res.Message : "获取项目数据失败！";
          ElMessage.error(msg);
        }
      });
    };
    const ticketOptions = ref([]);
    const getTicketsData = () => {
      AgentApi.ticketOptions().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            ticketOptions.value = res.Data;
          }
        } else {
          let msg = res.Message ? res.Message : "获取门票数据失败！";
          ElMessage.error(msg);
        }
      });
    };

    const typeOptions = ref([]);
    const getDiscountData = () => {
      AgentApi.discountOptions().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            typeOptions.value = res.Data;
          }
        } else {
          let msg = res.Message ? res.Message : "获取门票数据失败！";
          ElMessage.error(msg);
        }
      });
    };
    const agentTitle = ref("");
    function openDialog(id, title) {
      agentTitle.value = title;
      getProjectData();
      getTicketsData();
      getDiscountData();
      if (id) {
        //编辑

        AgentApi.configTicketsDetail({
          at_id: id,
        }).then((res) => {
          if (res.Code === 200) {
            const { Data } = res;
            Data["useDate"] = [Data.at_stime, Data.at_etime];
            formData.value = Data;
          } else {
            let msg = res.Message ? res.Message : "获取门票详情失败！";
            ElMessage.error(msg);
          }
        });
      } else {
        // 新增
        formData.value = {
          at_pid: "",
          at_stid: "",
          at_type: "",
          at_free_base_num: "",
          at_free_num: "",
          at_min_num: "",
          at_max_num: "",
          at_expire_day: "",
          useDate: "",
        };
      }
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            const data = JSON.parse(JSON.stringify(formData.value));
            data["at_stime"] = dayjs(formData.value.useDate[0]).format(
              "YYYY-MM-DD"
            );
            data["at_etime"] = dayjs(formData.value.useDate[1]).format(
              "YYYY-MM-DD"
            );
            if (+data["at_min_num"] > +data["at_max_num"]) {
              dialogRef.value.isLoading = false;
              ElMessage.warning("起购数量不能大于限购数量！");
              return false;
            }
            emit("submit", data);
          }
        });
      }
    }
    return {
      formData,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      ticketOptions,
      projectOptions,
      typeOptions,
      agentTitle,
    };
  },
};
</script>

<style lang="scss">
.agent-tickets-dialog {
  .el-input-group__append {
    border: 1px solid #fff;
  }
  .el-input-group__prepend {
    border: 1px solid #fff;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    color: var(--text-gray-color);
    background-color: var(--text-white-color) !important;
  }
  .hide {
    > label {
      visibility: hidden;
    }
  }
}
</style>