<template>
  <div class="agent-manage-container main-cnt">
    <div class="title">代理商列表</div>
    <div class="content">
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :filters="filters"
        :ispaging="true"
        :apiName="AgentApi.getAgentList"
        :columns="tableColumns"
        @edit="openAgentDialog"
        @resetPassword="openResetPasswordDialog"
        @configTickets="openConfigTicketsDialog"
        @statusChange="changeAgentStatus"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="openAgentDialog"
            v-if="authData.indexOf('n_raO4IMclPJNf5Ua2uCfHyIff0rt') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增代理商</el-button
          >
        </template>
      </common-table>
    </div>
    <AgentDialog ref="agentDialogRef" @submit="submitAgent"></AgentDialog>
    <ConfigTicketsDialog
      ref="configTicketsDialogRef"
      @reloadTable="reloadTable"
    ></ConfigTicketsDialog>
    <ResetPasswordDialog
      ref="resetPasswordDialogRef"
      @submit="submitPassword"
    ></ResetPasswordDialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { AgentApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import AgentDialog from "../components/AgentDialog.vue";
import ConfigTicketsDialog from "../components/ConfigTicketsDialog.vue";
import ResetPasswordDialog from "../components/ResetPasswordDialog.vue";
// 权限
const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

/** 表格对象 */
const tableRef = ref(null);
// 加载表格
onMounted(() => {
  tableRef.value.tableLoad();
});

// 表格筛选
const filters = ref([
  {
    filterType: "search",
    name: "keywords",
    value: "",
    placeholder: "搜索代理商名称、联系人、联系电话、证件号码",
  },
]);

/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "a_name",
    label: "代理商名称",
  },
  {
    prop: "type_text",
    label: "类型",
    color: "--text-third-color",
  },
  {
    type: "block",
    label: "已配置门票",
    prop: "agent_ticket_count",
    active: "configTickets",
    minWidth: 80,
    token: "n_vcadKmIMJbXsB6p6QoFbjwtK0od",
  },
  {
    prop: "a_contact",
    label: "联系人",
    color: "--text-third-color",
  },
  {
    prop: "a_contact_number",
    label: "联系电话",
  },
  {
    prop: "a_card_number",
    label: "证件号码",
  },
  {
    type: "switch",
    label: "状态",
    prop: "a_status",
    token: "n_5tfdFUrqBxkSeQZT3jy7VqPj2Dm",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 200,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "n_pt4DrWK4mdvVgeRcj1cPGpUUG0h",
      },
      {
        name: "重置密码",
        action: "resetPassword",
        className: "black-font-btn",
        token: "n_PxO0jzZQ4aPvwp4W5EXDzS9173s",
      },
    ],
  },
]);
// 更改代理商状态
function changeAgentStatus(row) {
  let data = {
    a_id: row.a_id,
    a_status: row.a_status == 1 ? 2 : 1,
  };
  AgentApi.changeAgentStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success(row.a_status == 1 ? "关闭成功！" : "开启成功！");
      tableRef.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
//已配置门票
const configTicketsDialogRef = ref(null);
function openConfigTicketsDialog(row) {
  configTicketsDialogRef.value.openDialog(row.a_id, row.a_name);
}
function reloadTable(flag) {
  flag && tableRef.value.tableLoad();
}
// 重置密码
const resetPasswordDialogRef = ref(null);
const passwordId = ref(null);
function openResetPasswordDialog(row) {
  passwordId.value = row.a_id;
  resetPasswordDialogRef.value.openDialog();
}
function submitPassword(data) {
  const parmas = {
    ...data,
    a_id: passwordId.value,
  };
  AgentApi.resetPassword(parmas).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("重置成功！");
      resetPasswordDialogRef.value.closeDialog();
      resetPasswordDialogRef.value.closeDialogLoading();
      tableRef.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "重置失败！";
      resetPasswordDialogRef.value.closeDialogLoading();
      ElMessage.error(msg);
    }
  });
}
// 新增/编辑代理商
const agentDialogRef = ref(null);
const openAgentDialog = (row) => {
  const rowData = row.a_id ? row : {};
  agentDialogRef.value.openDialog(rowData);
};
function submitAgent(data) {
  const url = data.a_id ? "editAgent" : "addAgent";
  AgentApi[url](data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      agentDialogRef.value.closeDialog();
      agentDialogRef.value.closeDialogLoading();
      tableRef.value.tableLoad();
    } else {
      agentDialogRef.value.closeDialogLoading();
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
</script>
<style lang="scss">
.agent-manage-container {
  font-family: "Source Han Sans CN";
  .search-input {
    width: 400px !important;
  }
  .content {
    padding: 15px 20px 20px;
  }
}
</style>
