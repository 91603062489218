<template>
  <div class="agent-dialog">
    <w-dialog
      ref="dialogRef"
      :title="formData.a_id ? '编辑代理商' : '新增代理商'"
      width="50%"
      btnWidth="140px"
      top="20vh"
      :confirmText="formData.a_id ? '确认编辑' : '确认新增'"
      @wConfirm="handleSure"
    >
      <el-form
        class="add-form"
        ref="formRef"
        :model="formData"
        :rules="formRules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="代理商名称" prop="a_name">
              <el-input
                v-model="formData.a_name"
                clearable
                placeholder="请输入代理商名称"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="代理商类型" prop="a_type">
              <el-select
                v-model="formData.a_type"
                placeholder="请选择代理商类型"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in agentOptions"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="联系人" prop="a_contact">
              <el-input
                v-model="formData.a_contact"
                clearable
                placeholder="请输入联系人"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="a_contact_number">
              <el-input
                v-model="formData.a_contact_number"
                placeholder="请输入联系电话"
                clearable
              ></el-input>
            </el-form-item>
          </el-col> </el-row
        ><el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="证件号码" prop="a_card_number">
              <el-input
                v-model="formData.a_card_number"
                clearable
                placeholder="请输入证件号码"
                @keyup="trimLR"
              ></el-input>
              <p class="tips">社会统一信用代码/身份证号</p>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import { checkStr } from "@/utils/common.js";
import { ElMessage } from "element-plus";
import { AgentApi } from "@/plugins/api.js";

export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const formData = ref({
      a_name: "",
      a_contact: "",
      a_type: "",
      a_contact_number: "",
      a_card_number: "",
    });
    function trimLR() {
      formData.value.a_card_number = formData.value.a_card_number.replace(
        /[, ]/g,
        ""
      ); // 去除首尾空格和中间空格
    }
    const agentOptions = ref([]);
    const dialogRef = ref(null);
    const formRules = reactive({
      a_name: [
        {
          required: true,
          message: "请输入代理商名称",
          trigger: "blur",
        },
      ],
      a_type: [
        {
          required: true,
          message: "请选择代理商类型",
          trigger: "change",
        },
      ],
      a_contact: [
        {
          required: true,
          message: "请输入联系人",
          trigger: "blur",
        },
      ],
      a_contact_number: [
        {
          required: true,
          message: "请输入联系电话",
          trigger: "blur",
        },
      ],
      a_card_number: [
        {
          required: true,
          message: "请输入证件号码",
          trigger: "blur",
        },
      ],
    });
    function getAgentTypeOptions() {
      AgentApi.agentTypeOptions().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            agentOptions.value = res.Data;
          }
        } else {
          let msg = res.Message ? res.Message : "获取代理商类型数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    function openDialog(data) {
      getAgentTypeOptions();
      if (data && data.a_id) {
        //编辑
        const lastData = JSON.parse(JSON.stringify(data));
        formData.value = lastData;
      } else {
        // 新增
        formData.value = {
          a_name: "",
          a_contact: "",
          a_type: "",
          a_contact_number: "",
          a_card_number: "",
        };
      }
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            const data = JSON.parse(JSON.stringify(formData.value));
            const regu = "^[ ]+$";
            const re = new RegExp(regu);
            if (
              re.test(data.a_name) ||
              re.test(data.a_contact) ||
              re.test(data.a_contact_number) ||
              re.test(data.a_card_number)
            ) {
              ElMessage.warning({
                message: "输入框的值不能为空格！",
              });
              closeDialogLoading();
              return false;
            }

            if (!checkStr(data.a_contact_number, "phone")) {
              ElMessage.warning({
                message: "联系电话格式不正确！",
              });
              closeDialogLoading();
              return false;
            }
            emit("submit", data);
          }
        });
      }
    }
    return {
      formData,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      agentOptions,
      trimLR,
    };
  },
};
</script>

<style lang="scss">
.agent-dialog {
  .tips {
    font-size: 12px;
    color: var(--text-gray-color);
  }
}
</style>
