<template>
  <div class="agent-password-dialog">
    <w-dialog
      ref="dialogRef"
      title="重置密码"
      width="30%"
      btnWidth="140px"
      top="20vh"
      confirmText="确认"
      @wConfirm="handleSure"
    >
      <el-form
        class="add-form"
        ref="formRef"
        :model="formData"
        :rules="formRules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="新密码" prop="password">
              <el-input
                v-model="formData.password"
                clearable
                placeholder="请输入新密码"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
import { ref, reactive } from "vue";

export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const formData = ref({
      password: "",
    });

    const dialogRef = ref(null);
    const formRules = reactive({
      password: [
        {
          required: true,
          message: "请输入新密码(同时包含字母和数字，且长度大于等于6！)",
          trigger: "blur",
          pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
        },
      ],
    });

    function openDialog() {
      formData.value = {
        password: "",
      };
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            const data = JSON.parse(JSON.stringify(formData.value));
            emit("submit", data);
          }
        });
      }
    }
    return {
      formData,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
    };
  },
};
</script>

<style lang="scss">
.agent-password-dialog {
  .tips {
    font-size: 12px;
    color: #cccccc;
  }
}
</style>